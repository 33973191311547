@font-face {
  font-family: "Raleway";
  src: local("Raleway"),
    url("./font/raleway/Raleway-Regular.ttf") format("truetype");
}
div {
  box-sizing: border-box;
}
h1,
h2,
p {
  font-family: "Raleway", sans-serif;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}
::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #222222;
}
/* ------------------ NAVBAR ------------------------- */
#primary-nav {
  height: 50px;
  width: 100%;
  background: #222222;
  display: flex;
  justify-content: center;
  z-index: 99;
}
.menu-container {
  height: 100%;
  width: 65%;
}
.menu {
  display: flex;
  height: 100%;
  align-items: center;
  float: right;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.menu-link {
  color: #fff;
  display: flex;
  cursor: pointer;
  align-items: center;
  text-decoration: none;
  padding: 0 1.2rem;
  height: 100%;
  font-family: "Raleway", sans-serif;
  letter-spacing: 3px;
  /* &.active {
    color: #000000;
  } */
}

/* ------------------ PAGES -------------------------- */
#navigation {
  position: absolute;
  display: flex;
  width: 100%;
}
.navigation-logo {
  align-items: center;

  .img-arro-logo {
    background-color: black;
    width: 150px;
    height: 150px;
  }
}

#header {
  position: initial;
  width: 100%;
  height: 95vh;
  .intro {
    width: 100%;
    height: 100%;
    padding: 0;
    background-image: url("../src/assets/header-bg.jpg");
    background-color: #e5e5e5;
    /* background-attachment: fixed; */
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
    .overlay {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .intro-text {
      margin-top: -50px;
    }
    .intro-text > h1 {
      color: #dfd7bf;
      font-size: 82px;
      font-weight: 700;
      letter-spacing: 15px;
      text-transform: uppercase;
      margin-top: 0;
      margin-bottom: 10px;
      text-align: center;
    }
    .intro-text > h2 {
      white-space: pre-line;
      color: #fff;
      font-size: 20px;
      font-weight: 100;
      line-height: 35px;
      margin-top: 25px;
      letter-spacing: 3px;
      text-align: center;
    }
  }
  .scroll-down-container {
    width: 100%;
    position: absolute;
    bottom: 50px;
    text-align: center;
    .fa-chevron-down {
      color: #dfd7bf;
      --fa-bounce-start-scale-x: 1;
      --fa-bounce-start-scale-y: 1;
      --fa-bounce-jump-scale-x: 1;
      --fa-bounce-jump-scale-y: 1;
      --fa-bounce-land-scale-x: 1;
      --fa-bounce-land-scale-y: 1;
      --fa-bounce-rebound: 0;
      --fa-bounce-height: 6px;
    }
  }
}

#about {
  padding: 100px 0px;
  .content > h2 {
    position: relative;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
  .content > h2:after {
    position: absolute;
    content: "";
    background-color: #dfd7bf;
    height: 4px;
    width: 60px;
    bottom: 0;
    left: 0;
  }
  .content > p {
    white-space: pre-line;
    margin-right: 60px;
    word-spacing: 3px;
    line-height: 30px;
  }
  .img {
    width: 500px;
  }
}

#works {
  .container {
    padding: 100px 0px;
  }
  .content-first {
    display: flex;
    justify-content: start;
  }
  .content-title > h2 {
    position: relative;
    margin-bottom: 100px;
    padding-bottom: 15px;
  }
  .content-title > h2:after {
    position: absolute;
    content: "";
    background-color: #dfd7bf;
    height: 4px;
    width: 60px;
    bottom: 0;
    left: 0;
  }
  .content-second {
    display: flex;
    justify-content: end;
  }
  .content-third {
    display: flex;
    justify-content: end;
    margin-top: -140px;
  }
  .works-item {
    width: 600px;
    .img {
      width: 350px;
      height: 350px;
      border-radius: 5px;
      object-fit: cover;
    }
  }
  .works-item > h3 {
    margin: 20px 0px;
  }
  .works-item > p {
    white-space: pre-line;
    word-spacing: 3px;
    line-height: 30px;
  }
}

#products {
  background: #222222;

  .container {
    padding: 50px 0px;
    .section-title {
      display: flex;
      justify-content: center;
    }
    .section-title > h2 {
      position: relative;
      margin-bottom: 30px;
      padding-bottom: 15px;
      color: white;
      width: 200px;
      text-align: center;
    }
    .section-title > h2:after {
      position: absolute;
      content: "";
      background-color: #dfd7bf;
      height: 4px;
      width: 60px;
      bottom: 0;
      left: 65px;
    }
    .product-box {
      width: 420px;
      height: 250px;
      margin-bottom: 25px;
    }
    .image-container {
      position: relative;
      width: 100%;
      height: 100%;
      margin: auto;
      overflow: hidden;
      border-radius: 5px;

      .image {
        width: 100%;
        height: 100%;
        background-color: wheat;
        border-radius: 5px;
      }
      .image-overlay {
        background: rgba(0, 0, 0, 0.7);
        position: absolute;
        border-radius: 5px;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        opacity: 0;
        -webkit-transition: all 0.4s ease-in-out 0s;
        -moz-transition: all 0.4s ease-in-out 0s;
        transition: all 0.4s ease-in-out 0s;
      }
      .image-details {
        position: absolute;
        text-align: center;
        padding-left: 1em;
        padding-right: 1em;
        width: 100%;
        top: 0%;
        left: 50%;
        opacity: 0;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        -webkit-transition: all 0.3s ease-in-out 0s;
        -moz-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
      }
      .image-details h3 {
        color: #fff;
        font-weight: 500;
        letter-spacing: 0.15em;
        margin-bottom: 0.5em;
        text-transform: uppercase;
      }
      .image-details p {
        color: #fff;
        font-size: 0.8em;
      }
    }
    .image-container:hover .image-overlay {
      opacity: 1;
    }
    .image-container:hover .image-details {
      top: 50%;
      left: 50%;
      opacity: 1;
    }
  }
}

#social {
  padding: 150px 0px;

  .content > h2 {
    position: relative;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
  .content > h2:after {
    position: absolute;
    content: "";
    background-color: #dfd7bf;
    height: 4px;
    width: 60px;
    bottom: 0;
    left: 0;
  }
  .content > p {
    white-space: pre-line;
    margin-bottom: 40px;
    word-spacing: 3px;
    line-height: 30px;
  }
  .img {
    width: 100%;
    height: 350px;
    border-radius: 5px;
    object-fit: cover;
  }
}

#contact {
  .footer-logo {
    width: 200px;
    float: left;
  }
  .footer-logo > img {
    width: 200px;
    object-fit: cover;
  }
  .footer-info {
    width: 250px;
    margin-left: 15px;
    .info {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
    }
    .info > p {
      width: 100%;
      margin: 0 auto;
      margin-left: 20px;
    }
  }
  .footer-info > h3 {
    letter-spacing: 3px;
    margin: 0;
    margin-bottom: 20px;
    font-weight: 1;
  }
  .info-fa-icon {
    width: 16px;
    height: 16px;
    color: #3f2305;
    margin-top: 3px;
  }
}
.contact-footer {
  width: 100%;
  height: 40px;
  background-color: #222222;
  display: flex;
  justify-content: center;
  align-items: center;
  .container > span {
    color: white;
    font-size: 12px;
  }
}

/* ------------------ RESPONSIVES -------------------------- */
@media only screen and (max-width: 1400px) {
  #works {
    .content-third {
      margin: 0;
    }
  }
}
@media only screen and (max-width: 685px) {
  #about {
    .img {
      width: 300px;
    }
  }
  #works {
    .works-item {
      width: 350px;
    }
    .content-first,
    .content-second,
    .content-third {
      justify-content: center;
    }
  }
}
